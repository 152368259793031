<template>
  <div class="outer">
    <h1 class="self-title">
      买家上门自提功能
    </h1>
    <p class="self-cont">
      启用上门自提功能后，买家可以就近选择你预设的自提点，下单后你需要尽快将商品配送至指定自提点；<br />
      该功能适用于参加各类农博展会活动，直接设置展位地址为自提点，并打印店铺或者商品二维码，用户可以直接扫码购物时选择现场提货。
    </p>
    <el-button
      type="primary"
      style="margin-bottom: 20px"
      @click="toAdd"
    >
      新增自提点
    </el-button>
    <tableBox
      ref="myTable"
      :api="$api.selfRaise.list"
      :api-data="apiData"
      :item-data="itemData"
      :page-name="{'page': 'page', 'limit': 'pagecount'}"
    />
  </div>
</template>

<script>
import tableBox from '@/components/common/table'
import {
  ITEM_DATA
} from './data'

export default {
  name: 'SelfRaise',
  components: {
    tableBox
  },
  data() {
    return {
      apiData: {},
      itemData: ITEM_DATA
    }
  },
  created() {
    this.addOptions()
  },
  mounted() {
  },
  methods: {
    // 添加table操作列
    addOptions() {
      const that = this
      const { itemData } = that
      const option = {
        prop: 'options',
        label: '操作',
        render(h, params) {
          const arr = [{
            name: '删除',
            doEvent: () => that.toDelete(params.row)
          }, {
            name: '编辑',
            doEvent: () => that.toEdit(params.row)
          }]
          return h('div', arr.map((item, index) => {
            return h('el-button', {
              domProps: {
                innerHTML: item.name
              },
              props: {
                type: 'text',
                size: 'small'
              },
              on: {
                click: item.doEvent
              }
            })
          }))
        }
      }
      if (itemData[itemData.length - 1].prop !== 'options') {
        that.itemData.push(option)
      }
    },
    toEdit(val) {
      this.$router.push({
        path: '/selfRaise/detail',
        query: { id: val.id }
      })
    },
    toDelete(val) {
      const that = this
      const url = that.$api.selfRaise.del
      that.$confirm('是否确认删除本条自提数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(res => {
        that.$axios.post(url, {
          ids: val.id
        }).then(res => {
          if (res.error === 0) {
            that.$message.success('删除成功')
            that.$router.go(0)
          }
        })
      })
    },
    toAdd() {
      this.$router.push('/selfRaise/detail')
    }
  }
}
</script>
<style lang="scss" scoped>
.self-title {
  font-weight: bolder;
  margin-bottom: 20px;
}
.self-cont {
  font-size: 14px;
  margin-bottom: 20px;
  line-height: 30px;
}
</style>
