var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "outer" },
    [
      _c("h1", { staticClass: "self-title" }, [_vm._v(" 买家上门自提功能 ")]),
      _vm._m(0),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary" },
          on: { click: _vm.toAdd },
        },
        [_vm._v(" 新增自提点 ")]
      ),
      _c("tableBox", {
        ref: "myTable",
        attrs: {
          api: _vm.$api.selfRaise.list,
          "api-data": _vm.apiData,
          "item-data": _vm.itemData,
          "page-name": { page: "page", limit: "pagecount" },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "self-cont" }, [
      _vm._v(
        " 启用上门自提功能后，买家可以就近选择你预设的自提点，下单后你需要尽快将商品配送至指定自提点；"
      ),
      _c("br"),
      _vm._v(
        " 该功能适用于参加各类农博展会活动，直接设置展位地址为自提点，并打印店铺或者商品二维码，用户可以直接扫码购物时选择现场提货。 "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }