const ITEM_DATA = [
  { prop: 'id', label: 'ID' },
  { prop: 'name', label: '自提点名称' },
  {
    prop: 'range_time',
    label: '时间',
    render(h, params) {
      return h('span', {
        domProps: {
          innerHTML: (params.row.type === 1 && params.row.range_time === '') ? '全天' : params.row.range_time
        }
      })
    }
  },
  { prop: 'addr', label: '地址' },
  { prop: 'mobile', label: '联系方式' },
  { prop: 'create_time', label: '创建时间' }
]

// 详情
const SITE_OPTION = {
  value: 'id',
  label: 'name',
  children: 'rows'
}
const FORM_DATA = {
  name: '',
  address: [],
  addressDetail: '',
  phone: '',
  timeType: 0,
  timeDetail: '',
  timeEffect: []
}
const FORM_RULES = {
  name: [
    { required: true, message: '请输入自提点名称', trigger: 'blur' }
  ],
  address: [
    { required: true, message: '请选择自提地址', trigger: 'change' }
  ],
  phone: [
    { required: true, message: '请输入联系电话', trigger: 'blur' }
  ],
  timeType: [
    { required: true, message: '请选择自提时间', trigger: 'change' }
  ],
  timeEffect: [
    { required: true, message: '请选择生效时间', trigger: 'change' }
  ]
}

export {
  ITEM_DATA,
  SITE_OPTION,
  FORM_DATA,
  FORM_RULES
}
